import React, { useContext, useEffect, useRef, useState } from "react";
import { GroupuiButton, GroupuiTab, GroupuiTabs } from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import GeneralInformationTab from "./GeneralInformationTab";
import StatusTab from "./StatusTab";
import useTitle from "../../hooks/useTitle";
import GenericMoreButton from "../generics/GenericMoreButton";
import AddStateModal from "./modals/AddStateModal";
import DataboxComments from "./DataboxComments";
import { ApiService } from "../../services/apiService";
import { useParams } from "react-router";
import UserGroupContext from "../context/UserGroupContext";
import { RightsWrapper } from "../container/RightsWrapper";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { Comment } from "../../models/comment";
import { RightsManagement } from "../../config/rightsManagement";
import EditProjectHolderPage, { RefType } from "../myDataboxes/EditProjectHolderPage";
import { Order } from "../../models/order";
import { DataboxPoolContext } from "../context/provider/DataboxPoolContext";
import InitializeDataboxModal from "./modals/InitializeDataboxModal";
import { MediaStateType } from "../../enums/MediaStateType";

/**
 * A DataboxDetails Component, which defines the different tabs displayed on the databox details page
 *
 * @returns DataboxDetails Component
 *
 */
function DataboxDetails() {
    useTitle("Databox Details");
    const { databoxID } = useParams();
    const { userGroups } = useContext(UserGroupContext);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { currentDatabox } = useContext(DataboxPoolContext);

    const [commentData, setCommentData] = useState<Comment[]>();
    const [dropDownState, setDropDownState] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [currentTabId, setCurrentTabId] = useState<string>("firstTabId");
    const [modal, setModal] = useState(false);

    const [order, setOrder] = useState<Order | null>();
    const [editProjectHolder, setEditProjectHolder] = useState<boolean>(false);
    const refEditProject = useRef<RefType>(null);
    const [initDataboxModal, setInitDataboxModal] = useState<boolean>(false);

    useEffect(() => {
        ApiService.getAllCommentsForDatabox(Number(databoxID))
            .then((response) => {
                setCommentData(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });

        ApiService.getOrderByDataboxId(Number(databoxID))
            .then((response) => {
                setOrder(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const handleEditMode = () => {
        setEditMode(!editMode);
    };

    const saveEditProject = () => {
        if (refEditProject.current) {
            refEditProject.current.sendChanges();
        }
    };

    return (
        <>
            <RightsWrapper
                key={"rights-first"}
                authorizedUserGroup={RightsManagement.ADD_DATABOX_STATE}
                userGroups={userGroups}
            >
                <AddStateModal
                    modal={modal}
                    setModal={() => setModal(false)}
                    currentObjectId={databoxID}
                />
            </RightsWrapper>
            <InitializeDataboxModal
                databoxId={currentDatabox?.id ?? -1}
                ingestStationId={currentDatabox?.ingestStation?.id ?? -1}
                modal={initDataboxModal}
                setModal={setInitDataboxModal}
            />
            <GenericHeadline
                title={
                    currentDatabox ? `Databox Details - ${currentDatabox?.name}` : `Databox Details`
                }
                actionButtons={[
                    currentTabId == "firstTabId" ? (
                        !editMode ? (
                            !editProjectHolder ? (
                                <GenericMoreButton
                                    key={"More-Button-2"}
                                    dropDownState={dropDownState}
                                    setDropDownState={() => {
                                        setDropDownState(!dropDownState);
                                    }}
                                    options={[
                                        <RightsWrapper
                                            key={"rights-first"}
                                            authorizedUserGroup={RightsManagement.ADD_DATABOX_STATE}
                                            userGroups={userGroups}
                                        >
                                            <GroupuiButton
                                                key={"c1"}
                                                icon="edit-24"
                                                variant="primary"
                                                fullwidth={true}
                                                alignment="left"
                                                onClick={() => {
                                                    setEditMode(true);
                                                    setDropDownState(!dropDownState);
                                                }}
                                            >
                                                Edit Databox
                                            </GroupuiButton>
                                        </RightsWrapper>,
                                        <RightsWrapper
                                            authorizedUserGroup={
                                                RightsManagement.EDIT_PROJECT_HOLDER
                                            }
                                            userGroups={userGroups}
                                            key={"Option-4-RW"}
                                        >
                                            <GroupuiButton
                                                key={"Option-4"}
                                                variant="primary"
                                                icon="edit-32"
                                                fullwidth={true}
                                                alignment="left"
                                                disabled={order === undefined || order === null}
                                                onClick={() => {
                                                    setEditProjectHolder(true);
                                                    setDropDownState(false);
                                                }}
                                            >
                                                Edit Project/Holder
                                            </GroupuiButton>
                                        </RightsWrapper>,
                                        <RightsWrapper
                                            authorizedUserGroup={
                                                RightsManagement.INITIALIZE_DATABOX
                                            }
                                            userGroups={userGroups}
                                            key={"Option-5-RW"}
                                        >
                                            <GroupuiButton
                                                key={"Option-4"}
                                                variant="primary"
                                                icon="edit-32"
                                                fullwidth={true}
                                                alignment="left"
                                                disabled={
                                                    !(
                                                        currentDatabox?.mediaStateType
                                                            .mediaStateType ===
                                                            MediaStateType.FREE ||
                                                        currentDatabox?.mediaStateType
                                                            .mediaStateType ===
                                                            MediaStateType.RESERVED
                                                    )
                                                }
                                                onClick={() => {
                                                    setInitDataboxModal(true);
                                                }}
                                            >
                                                Initialize Databox
                                            </GroupuiButton>
                                        </RightsWrapper>,
                                    ]}
                                />
                            ) : (
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <GroupuiButton
                                        variant="secondary"
                                        icon="close-24"
                                        onClick={() => setEditProjectHolder(false)}
                                    >
                                        Cancel
                                    </GroupuiButton>
                                    <GroupuiButton icon="save-24" onClick={() => saveEditProject()}>
                                        Save
                                    </GroupuiButton>
                                </div>
                            )
                        ) : (
                            <></>
                        )
                    ) : currentTabId == "secondTabId" ? (
                        <GenericMoreButton
                            key={"More-Button-1"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <GroupuiButton
                                    key={"Option-1"}
                                    variant="primary"
                                    icon="add-32"
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setModal(true);
                                        setDropDownState(false);
                                    }}
                                >
                                    Add State
                                </GroupuiButton>,
                            ]}
                        />
                    ) : (
                        // Render an empty div for any other tabId, implying the third option or any other unhandled ID
                        <div key={"empty-div"} />
                    ),
                ]}
            />
            <GroupuiTabs
                onGroupuiTabChange={(event) => {
                    setCurrentTabId(event.detail.tab);
                    setDropDownState(false);
                }}
            >
                <GroupuiTab style={{ overflow: "unset" }} tab="firstTabId" name="General">
                    {editProjectHolder === false ? (
                        <GeneralInformationTab
                            editMode={editMode}
                            handleEditMode={handleEditMode}
                        />
                    ) : (
                        order && <EditProjectHolderPage orderId={order?.id} ref={refEditProject} />
                    )}
                </GroupuiTab>

                <GroupuiTab tab="secondTabId" name="Status" disabled={editMode ?? true}>
                    <StatusTab />
                </GroupuiTab>
                <GroupuiTab
                    tab="thirdTabId"
                    name={`Comments (${commentData?.length ?? "0"})`}
                    disabled={editMode ?? true}
                >
                    <DataboxComments comments={commentData} />
                </GroupuiTab>
            </GroupuiTabs>
        </>
    );
}

export default DataboxDetails;
